export default {
    data() {
        return {
            window: {
                width: 0,
                height: 0
            }
        }
    },
    mounted() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    },
    methods: {
        updateWindowDimensions() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    computed: {
        isMobile() {
            return this.window.width < 991;
        }
    }
}